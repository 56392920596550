import React from 'react';
import CVLandingPage from './CVLandingPage';

function App() {
  return (
      <div className="App">
        <CVLandingPage />
      </div>
  );
}

export default App;